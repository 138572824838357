import React, { useState } from "react";
import { Link } from "react-router-dom";

import '../Styles/navBar.css';
import 'antd/dist/antd.css';
import {Col, Row} from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

function Navbar() {

    const[active, setActive] = useState(false);

  return (
    <Col span={24} className='navBarContainer'>
        <div className='navBar'>
            <Link to='/' className='item1'>&copy; Collab Shooting 2021</Link>

            <div className='navbar-items'>
                <Link to='/' className='item'>Home</Link>
                <Link to='/how-it-works' className='item'>How it works</Link>
                <Link to='/' className='item'>Press</Link>
                <Link to='/tos' className='item'>Terms</Link>
                <Link to='/privacy' className='item'>Privacy</Link>
                <a href='https://www.instagram.com/collabshooting/' className='item'>
                    <img width='20' height='20' src='../assets/instagram.svg' />
                </a>
                
            </div>
            <div>
                <a href='mailto:hello@collabshooting.com' className='item1'>hello@collabshooting.com</a>
                <img width='30' src='../assets/star4.svg' style={{marginLeft: 8, marginTop: '-1%'}} />
            </div>
        </div>

        <Col span={24} className='itemsMobile'>
            <Link to='/' className='item'>Home</Link>
            <Link to='/how-it-works' className='item'>How it works</Link>
            <Link to='/' className='item'>Press</Link>
            <Link to='/tos' className='item'>Terms</Link>
            <Link to='/privacy' className='item'>Privacy</Link>
            <a href='https://www.instagram.com/collabshooting/' className='item'>
                <img width='30' height='30' src='../assets/instagram.svg' />
            </a>
            <a href='mailto:hello@collabshooting.com' className='item'>hello@collabshooting.com</a>
            <Link to='/' className='item1'>&copy; Collab Shooting 2021</Link>
            <img width='30' src='../assets/star4.svg' style={{marginBottom: '10%'}} />
        </Col>
    </Col>
  );
}

export default Navbar;
