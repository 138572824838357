import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import '../Styles/home.css';
import 'antd/dist/antd.css';
import {Col, Row} from 'antd';

import Navbar from '../Components/NavBar';

function Home() {

    useEffect(()=> {
        (()=> {
            window.scroll(0, 0);
        })();
    }, [])

  return (
    <Col span={24}>
        
        <video autoPlay muted loop id="myVideo" 
            src="../assets/videos/pexels-cottonbro-5788801.mp4"
        />

        <div className="content">

            <div className='container1'>

                <div style={{position: 'relative', width: 236, height: 470, overflow: 'hidden', borderRadius: 20, background: '#fff'}}>
                    <video autoPlay muted loop src="../assets/videos/FullSizeRender.mp4" width='230' height='470' style={{marginLeft: 3}} />
                </div>

                {/* <img style={{borderRadius: 20}} width='230' height='440' src='../assets/pic.png' /> */}
                {/* <iframe style={{borderRadius: 20}} width='250' height='400' src='https://www.youtube.com/embed/yabDCV4ccQs' /> */}

                <div className='downloadContainer'>

                    <div className='logANdCollabContainer'>
                        <img src='../assets/LogoCopy.png' width='45' height='48' />
                        <p className='collabText'>collab</p>
                    </div>
                    <img src='../assets/baseline.svg' width='500' style={{marginTop: '8%'}} />

                    <div className='imgItems'>
                        <a href='https://apps.apple.com/fr/app/collab-shooting/id1568937075'>
                            <img width='170' height='65' src='../assets/download-on-the-app-store.svg' />
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.collabshooting.collabshooting" style={{marginLeft: 10}}>
                            <img width='200' height='82' src='../assets/en_badge_web_generic.png' />
                        </a>
                    </div>

                    <img src='../assets/swipe-match-collab.svg' width='200' style={{marginTop: '5%', marginRight: '1%'}} />
                </div>
            </div>

            <div className='containerMobile'>
                <div className='logANdCollabContainer'>
                    <img src='../assets/LogoCopy.png' width='35' height='38' />
                    <p className='collabText'>collab</p>
                </div>

                <img src='../assets/baseline.svg' width='350' style={{marginBottom: '10%', marginTop: '2%'}} />

                {/* <img style={{borderRadius: 20}} width='230' height='440' src='../assets/pic.png' /> */}
                <div style={{width: 230, height: 440, overflow: 'hidden', borderRadius: 20, background: '#fff', paddingTop: 3}}>
                    <video autoPlay playsInline muted loop src="../assets/videos/FullSizeRender.mp4" width='230' height='434' />
                </div>

                <img src='../assets/swipe-match-collab.svg' width='250' style={{marginTop: '13%'}} />

                <div className='imgItems'>
                    <a href='https://apps.apple.com/fr/app/collab-shooting/id1568937075'>
                        <img width='170' height='65' src='../assets/download-on-the-app-store.svg' />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.collabshooting.collabshooting">
                        <img width='200' height='82' src='../assets/en_badge_web_generic.png' />
                    </a>
                </div>

            </div>

            <Navbar />
        </div>
        
    </Col>
  );
}

export default Home;
