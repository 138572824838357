import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './Pages/Home.jsx';
import Privacy from './Pages/privacy';
import TermsOfService from './Pages/termsOfService';
import Howitwork from './Pages/howItWork';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact><Home /> </Route>
        <Route path="/tos" exact><TermsOfService /> </Route>
        <Route path="/privacy" exact><Privacy /> </Route>
        <Route path="/how-it-works" exact><Howitwork /> </Route>
      </Switch>
    </Router>
  );
}

export default App;
