import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import '../Styles/tos.css';
import 'antd/dist/antd.css';
import {Col, Row} from 'antd';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function TermsOfService() {

    useEffect(()=> {
        (()=> {
            window.scroll(0, 0);
        })();
    }, [])

  return (
    <Col span={24} >
        
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            
            <Viewer fileUrl="../assets/pdf/tos.pdf" />

        </Worker>
        
    </Col>
  );
}

export default TermsOfService;