import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import '../Styles/Howitwork.css';
import 'antd/dist/antd.css';
import {Col, Row} from 'antd';

function Howitwork() {

    useEffect(()=> {
        (()=> {
            window.scroll(0, 0);
        })();
    }, [])

  return (
    <Col span={24}>

      <Col className='containerHeadHowItWork'>
        <p>✨😁📸</p>
      </Col>

      <Link to='/'>
        <img src='/assets/logo_googlestore.png' className='logoPosition' />
      </Link>

      <img src='/assets/hello.png' className='logoHello' />

      <div >
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='questionContainer'>
            <p className='question'>✨ How does it work?</p>
            <p className='response'>
              Pretty much like Tinder, but with <strong className='strongHeavy'>no dating</strong> ❌
              <br /> <br/>
              Basically, the idea is for you to <strong className='strongHeavy'>swipe</strong> until you <strong className='strongHeavy'>match</strong> with other members! And then meet for a <strong className='strongHeavy'>photoshoot</strong>.
              <br /><br/>
              No more weird or unread messages on Instagram / Tinder / Facebook groups to find models or photographers. No, now they are all in the same place and <strong className='strongHeavy'>you only match with people whose creative asthethics you have already liked!</strong>
              <br /><br/>
              No more waste of time. It's <strong className='strongHeavy'>efficient</strong>, <strong className='strongHeavy'>simple</strong> and <strong className='strongHeavy'>secure</strong>.
              <br /><br />
              ↳ Create your <strong className='strongHeavy'>account</strong>, pick your <strong className='strongHeavy'>collab type</strong> (free or paid), your <strong className='strongHeavy'>shooting type</strong> (indoors, outdoors, studio, etc), upload <strong className='strongHeavy'>4 photos</strong> + a bit of information about yourself, <strong className='strongHeavy'>filter</strong> according to your needs and <strong className='strongHeavy'>you are all set!</strong>
            </p>
          </div>
        </div>
        
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='questionContainer'>
            <p className='question'>🔮 What can I use this app for?</p>
            <p className='response'>
              Doesn't matter if you are a beginner or a pro, you can <strong className='strongHeavy'>join</strong> (it's free!) and <strong className='strongHeavy'>showcase your creativity!</strong>
              <br /><br />
              <strong className='strongHeavy'>Collab</strong> can be useful to you if :<br /><br />
              <ul>
                <li>You want to <strong className='strongHeavy'>create</strong> or <strong className='strongHeavy'>expand</strong> your portfolio as a model or a photographer </li><br/>
                <li>You want to <strong className='strongHeavy'>meet more people</strong> in the photography / modeling / fashion industry, or maybe just meet new passionate and creative people</li><br/>
                <li>You want to <strong className='strongHeavy'>create more content</strong> for your Instagram or any other social media platform</li><br/>
                <li>If for any reason you need a model or a photographer for your <strong className='strongHeavy'>project</strong>, your <strong className='strongHeavy'>brand</strong>, etc</li><br/>
              </ul>
              And <strong className='strongHeavy'>more!</strong> Creativity knows no bounds 💫          
            </p>
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='questionContainer'>
            <p className='question'>💰 Do I have to pay at some point?</p>
            <p className='response'>
              <strong className='strongHeavy'>Collab</strong> is free and will remain <strong className='strongHeavy'>free forever</strong>.
              <br /><br/>
              However we are using a freemium model, meaning if you like the app and you wish to have <strong className='strongHeavy'>access to all the features</strong> then you can choose a subscription model to unlock <strong className='strongHeavy'>Collab</strong>✚ ✨ (don't worry you can use the app perfectly fine with the free version).
              <br /><br/>
              With <strong className='strongHeavy'>Collab</strong>✚ ✨ you will be able to <strong className='strongHeavy'>rewind your last swipe, see who has already liked your profile, disable all ads, increase your profile visibility, unlock all filters, receive x3 TopLike weekly</strong> and be able to <strong className='strongHeavy'>activate the Travel Mode</strong> whenever you feel necessary. 
              <br /><br/>
              It's up to you! Either way, thank you for using <strong className='strongHeavy'>Collab</strong> 🤎
            </p>
          </div>
        </div>
        
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='questionContainer'>
            <p className='question'>🛡 How is it secured?</p>
            <p className='response'>
              First of all, we strongly recommend our members to <strong className='strongHeavy'>get their profile verified.</strong> It will require a <strong className='strongHeavy'>selfie</strong> and a <strong className='strongHeavy'>photo of your ID</strong>.
              <br /><br/>
              Once it's done you will have the possibility to browse only amongst other members who have also verified their profiles.
              <br /><br/>
              Furthermore, we have created a <strong className='strongHeavy'>system of rating</strong> inside the app. After every collab we encourage our members to <strong className='strongHeavy'>leave a review</strong> to each other. By doing that we will naturally remove unprofessional people from our community.
            </p>
          </div>
        </div>
        
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div className='questionContainer'>
            <p className='question'>⚔️ What happens if someone is using the app to find dates?</p>
            <p className='response'>
              Well, we have a <strong className='strongHeavy'>0 tolerance policy</strong> about this. 
              <br /><br/>
              If someone is hitting on you through this app (or any other unsolicited behavior), feel free to report it with a screenshot of the conversation by clicking on the 3 dots "●●●" in the conversation and then on "<strong className='strongHeavy'>Report</strong>".
              <br /><br/>
              After verification, this person will be <strong className='strongHeavy'>banned permanently</strong>.
              <br /><br/>
              And finally, we count on each and every one of you to <strong className='strongHeavy'>report</strong> to us any <strong className='strongHeavy'>wrong behavior</strong> from another member.
              <br /><br/>
              At <strong className='strongHeavy'>Collab</strong>, we value <strong className='strongHeavy'>respect and kindness</strong> above all. We expect members to remain <strong className='strongHeavy'>pro at all time.</strong>
            </p>
          </div>
        </div>
        
      </div>

      <p className='endText'>
        Any more questions?<br />
        <a href="mailto:hello@collabshooting.com">hello@collabshooting.com</a>
      </p>

      <p className='copyRight'>
        &copy; Collab Shooting 2021
      </p>
        
    </Col>
  );
}

export default Howitwork;